import "./VenueInformation.scss";

import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import GooglePlacesAutocomplete, {
  geocodeByPlaceId,
} from "react-google-places-autocomplete";
import { useNavigate, useParams } from "react-router-dom";
import { v4 } from "uuid";

import { auth } from '../../firebase/firebaseConfig';


import checkmark from "../../assets/images/icons/check-mark.svg";
import InputForm from "../InputForm/InputForm";
import api from "../../services/api";
import parseMinutesToHourString from "../../utils/parseMinutesToHourString";
import { HoursOfOperation } from "../HoursOfOperation";
import Image from "../Image";
import * as Yup from "yup";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SelectForm from "../SelectForm/SelectForm";
import LoadingPage from "../LoadingPage/LoadingPage";

const VenueInformation = ({ specVenue: specVenueProps = {}, view = false }) => {
  const navigate = useNavigate();
  const { venueId } = useParams();
  const [specVenue, setSpecVenue] = useState(specVenueProps);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getVenue = async () => {
      setLoading(true);
      try {
        if (!venueId) {
          setLoading(false);
          return;
        }

        const response = await api.get(`/venues/${venueId}`);
        setSpecVenue(response.data);

        setLoading(false);
      } catch (err) {
        toast.success(
          `error retreiving specific venue. this was the error: ${err}`
        );
      } finally {
      }
    };
    getVenue();
  }, [venueId]);

  const [additionalHours, setAdditionalHours] = useState([]);
  const [updatedHour, setUpdatedHour] = useState();

  const [funds, setFunds] = useState("")

  const [updatedName, setUpdatedName] = useState("");

  const [updatedAddress, setUpdatedAddress] = useState("");
  const [addressInputValue, setAddressInputValue] = useState("");

  const [updatedUrl, setUpdatedUrl] = useState("");
  const [updatedEmail, setUpdatedEmail] = useState("");

  // const [updatedPrice, setUpdatedPrice] = useState("");

  const [updatedAbout, setUpdatedAbout] = useState("");
  const [updatedPhone, setUpdatedPhone] = useState("");
  const [updatedLat, setUpdatedLat] = useState("");
  const [updatedLong, setUpdatedLong] = useState("");
  const [updatedState, setUpdateState] = useState("");
  const [updatedCountry, setUpdateCountry] = useState("");
  const [updatedCat, setUpdatedCat] = useState([]);

  const [updateSpecials, setUpdateSpecials] = useState("");

  const [updatedDress, setUpdatedDress] = useState();
  const [updatedMusic, setUpdatedMusic] = useState([]);

  const [updateCity, setUpdateCity] = useState("");

  const [placeId, setPlaceId] = useState();

  const [images, setImages] = useState([{ id: v4() }]);
  const [errors, setErrors] = useState({});
  const [updatedLocation, setUpdatedLocation] = useState("");
  const [locationsOptions, setLocationOptions] = useState([]);

  const validationSchema = Yup.object().shape({
    // updatedName: Yup.string().required("Name is Required"),
    // updatedCat: Yup.array().of(Yup.string()).min(1, "Select at least one category"),
    // updatedAddress: Yup.string().required("Select one"),
    // updatedPhone: Yup.string().required("Phone is Required"),
    // updatedUrl: Yup.string().required("Web Site is Required"),
    // updatedEmail: Yup.string().required("Email is Required"),
    // // updatedPrice: Yup.string().required("Price is Required"),
    // updatedAbout: Yup.string().required("About is Required"),
    // updateSpecials: Yup.string().required("Specials is Required"),
    // updatedMusic: Yup.array().of(Yup.string()).min(1, "Select at least one music type"),
    // updatedDress: Yup.mixed().test(
    //   'is-string-or-array',
    //   'Select one',
    //   function (value) {
    //     if (typeof value === 'string') {
    //       return true;
    //     }
    //     if (Array.isArray(value) && value.every(item => typeof item === 'string')) {
    //       return true;
    //     }
    //     return false;
    //   }
    // ).required("Select one"), updateMapIcon: Yup.string().required("Select one"),



  });

  const handleEditVenueSubmit = async (event) => {
    event.preventDefault();

    const parsedHours = additionalHours.reduce((acc, cur) => {
      const { day, hours } = cur;

      acc[day] = hours;

      return acc;
    }, {});

    if (updatedHour) parsedHours[updatedHour.day] = updatedHour.hours;

    try {
      await validationSchema.validate(
        {
          // updatedName,
          // updatedCat,
          // updatedAddress,
          // updatedPhone,
          // updatedUrl,
          // updatedEmail,
          // // updatedPrice,
          // updatedAbout,
          // updatedDress,
          // updatedMusic,
          // updateMapIcon,
          // updateSpecials
          funds
        },
        { abortEarly: false }
      );

      const updatedVenue = {
        name: updatedName,
        tags: updatedCat,
        address: updatedAddress,
        hours: parsedHours,
        url: updatedUrl,
        // price: updatedPrice,
        email: updatedEmail,
        phone: updatedPhone,

        lat: updatedLat,
        lng: updatedLong,

        dress: updatedDress,
        music: updatedMusic,
        description: updatedAbout,
        city: updateCity,
        state: updatedState,
        country: updatedCountry,
        specials: updateSpecials,

        // funds: funds,

        location: updatedLocation,
        // preferences: updatePreferences,

        place_id: placeId,
      };

      if (user?.admin) {
        updatedVenue.funds = parseFloat(funds);
      }
      const updateVenue = async () => {
        try {
          await api.put(`/venues/${specVenue._id}`, updatedVenue);

          toast.success("Business Edited successfully!");

          navigate("/business");
        } catch (err) {
          console.log(`error updating this venue. this was the error: ${err}`);
        }
      };
      updateVenue();
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const validationErrors = {};
        error.inner.forEach((err) => {
          validationErrors[err.path] = err.message;
        });

        setErrors(validationErrors);
        toast.error(`Error Edited Venue. Please try again, ${error.message}`);
      } else {
        console.log(error);
      }
    }
  };

  const markeIcon = [
    { label: "Bar", value: "bar" },
    { label: "Club", value: "club" },
    { label: "Lounge", value: "lounge" },
  ];

  const handleImageAdd = useCallback(
    async (event) => {
      if (!specVenue?._id) return;

      const file = event.target.files[0];

      const formData = new FormData();

      formData.append("images", file);

      const { data } = await api.post(
        `/venues/images/${specVenue._id}`,
        formData
      );
      const recentImageAdded = data[0];

      const image = { id: recentImageAdded };

      setImages((oldState) => [...oldState, image]);

      alert("Image uploaded successfully!");
    },
    [specVenue]
  );

  const handleImageRemove = useCallback(
    async (id) => {
      if (!specVenue?._id) return;

      await api.delete(`/venues/images/${specVenue._id}`, {
        params: {
          images: [id],
        },
      });

      setImages((oldState) => {
        const updatedState = oldState.filter((i) => i.id !== id);

        return updatedState;
      });

      alert("Image DELETED successfully!");
    },
    [specVenue]
  );

  const days = useMemo(() => {
    return [
      "sunday",
      "monday",
      "tuesday",
      "wednesday",
      "thursday",
      "friday",
      "saturday",
    ];
  }, []);

  useEffect(() => {
    setUpdatedName(specVenue.name || "");
    setUpdatedLong(specVenue.lng || "");
    setUpdatedLat(specVenue.lat || "");
    setUpdatedUrl(specVenue.url || "");
    setUpdatedPhone(specVenue.phone || "");

    // setUpdatedPrice(specVenue.price || "");

    setUpdatedEmail(specVenue.email || "");
    setUpdatedDress(specVenue.dress || "");
    setUpdatedMusic(specVenue.music || "");
    setUpdatedAbout(specVenue.description || "");
    setUpdatedCat(specVenue.category || "");
    setUpdatedCat(specVenue.tags || "");
    setUpdatedLocation(specVenue.local || "");

    // setUpdateLocation(specVenue.location || "");

    setUpdatedAddress(specVenue.address || "");
    setAddressInputValue(specVenue.address || "")
    setUpdateCity(specVenue.city || "");
    setUpdateSpecials(specVenue.specials || "");

    if (specVenue?.image?.length) {
      const imgs = specVenue.image.map((img, index) => {
        return {
          id: img,
          url: specVenue.image_urls[index],
        };
      });

      setImages([...imgs, { id: v4() }]);
    }

    const parsedHours = Object.entries(specVenue.hours || {}).reduce(
      (acc, [key, value]) => {
        const hour = {
          day: key,
          hours: value,
        };

        acc.push(hour);

        return acc;
      },
      []
    );

    const sortedHours = parsedHours.sort((a, b) => {
      const aIndex = days.indexOf(a.day);
      const bIndex = days.indexOf(b.day);

      return aIndex - bIndex;
    });

    setUpdatedHour(sortedHours[0]);
    setAdditionalHours(sortedHours.slice(1, sortedHours.length));

    // setUpdatePreferences(specVenue.preferences || "");
  }, [specVenue, days]);

  const handleCategoryChange = (selectedOptions) => {
    const selectedValues = Array.isArray(selectedOptions)
      ? selectedOptions.map((option) => option.value)
      : [];

    setUpdatedCat(selectedValues);
  };

  const handleDressChange = (selectedOptions) => {
    setUpdatedDress(selectedOptions ? selectedOptions.value : "");
  };



  const handleMusicChange = (selectedOption) => {
    const selectValues = Array.isArray(selectedOption)
      ? selectedOption.map((option) => option.value)
      : [];

    setUpdatedMusic(selectValues);
  };

  const handleSelect = async (result) => {
    try {
      const placeDetails = await geocodeByPlaceId(result.value.place_id);
      const {
        address_components,
        formatted_address,
        geometry: {
          location: { lat, lng },
        },
      } = placeDetails[0];

      const city = address_components.find((address) =>
        address.types.some((type) => type === "administrative_area_level_2")
      );
      const state = address_components.find((address) =>
        address.types.some((type) => type === "administrative_area_level_1")
      );
      const country = address_components.find((address) =>
        address.types.some((type) => type === "country")
      );

      if (city) setUpdateCity(city.long_name);
      if (state) setUpdateState(state.short_name);
      if (country) setUpdateCountry(country.short_name);

      setUpdatedAddress(formatted_address);
      setAddressInputValue(formatted_address);
      setUpdatedLat(lat);
      setUpdatedLong(lng);
      setPlaceId(result.value.place_id);
    } catch (error) {
      console.error("Error in handleSelect:", error);
    }
  };

  const omitDays = useMemo(() => {
    const days = additionalHours.reduce((acc, cur) => {
      const { day } = cur;

      if (day) {
        acc.push(day);

        return acc;
      }

      return acc;
    }, []);

    if (updatedHour?.day) days.push(updatedHour.day);

    return days;
  }, [updatedHour, additionalHours]);

  const handleOnDayChange = (index, event) => {
    const { value } = event;

    setAdditionalHours((oldState) => {
      const updatedState = [...oldState];

      const { hours } = updatedState[index];

      updatedState[index] = {
        day: value,
        hours,
      };

      return updatedState;
    });
  };

  const handleAddHours = () => {
    const newHour = {
      hours: {},
      day: "",
    };

    setAdditionalHours((oldState) => [...oldState, newHour]);
  };

  const handleRemoveHours = (index) => {
    setAdditionalHours((oldState) => {
      const newState = [...oldState];

      newState.splice(index, 1);

      return newState;
    });
  };

  const handleOnTimeChange = (index, event) => {
    const { value, type } = event;

    setAdditionalHours((oldState) => {
      const newHour = { [type]: value };
      const updatedState = [...oldState];

      const { hours, day } = updatedState[index];

      updatedState[index] = {
        day,
        hours: { ...hours, ...newHour },
      };

      return updatedState;
    });
  };

  const defaultHourValue = useMemo(() => {
    if (!updatedHour) return undefined;

    return {
      day: updatedHour.day,
      hours: {
        from: updatedHour?.hours?.from
          ? parseMinutesToHourString(updatedHour.hours.from)
          : "00:00",
        to: updatedHour?.hours?.to
          ? parseMinutesToHourString(updatedHour.hours.to)
          : "00:00",
      },
    };
  }, [updatedHour]);

  const googlePlacesTypesToFetch = ["establishment"];

  useEffect(() => {
    if (!updatedAddress && specVenue.address) {
      setUpdatedAddress(specVenue.address);
      setAddressInputValue(specVenue.address);
    }
  }, [updatedAddress, specVenue.address]);

  const [filters, setFilters] = useState();
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [dressCodeOptions, setDressCodeOptions] = useState([]);
  const [musicOptions, setMusicOptions] = useState([]);

  useEffect(() => {
    async function getFilters() {
      try {
        const response = await api.get("filters");

        const tags = response.data.tags;
        const dressCode = response.data.dress_code;
        const music = response.data.music;
        const location = response.data.location;

        const formattedTags = tags.map((tag) => ({ value: tag, label: tag }));
        const formattedDressCode = dressCode.map((tag) => ({
          value: tag,
          label: tag,
        }));
        const formattedMusic = music.map((tag) => ({ value: tag, label: tag }));
        const locationOptions = location.map(tag => ({ value: tag, label: tag }));

        setCategoryOptions(formattedTags);
        setDressCodeOptions(formattedDressCode);
        setMusicOptions(formattedMusic);
        setLocationOptions(locationOptions);
        setFilters(response.data);
      } catch (err) {
        console.log(err);
      }
    }

    getFilters();
  }, []);

  const [user, setUser] = useState();


  useEffect(() => {
    async function loadUser() {
      const { uid } = auth?.currentUser;

      console.log(uid)

      const { data } = await api.get(`/users/${uid}`);

      setUser(data.user);
    }

    loadUser();
  }, []);

  return (
    <article className="venue-info">
      {loading ? (
        <>
          <LoadingPage />
        </>
      ) : (
        <form onSubmit={handleEditVenueSubmit} className="venue-info__form">
          <div className="venue-info__form--left">
            <div className="venue-info__form-group">
              <div className="venue-info__form-group-field">
                <InputForm
                  label="Name"
                  type="text"
                  id="venueName"
                  name="name"
                  value={updatedName}
                  onChange={(event) => setUpdatedName(event.target.value)}
                  error={errors.updatedName}
                />
              </div>

              <div className="venue-info__form-group-field">
                <SelectForm
                  label="Category"
                  isMulti
                  options={categoryOptions}
                  className="venue-info__form-group-field-select"
                  value={categoryOptions.filter((option) =>
                    updatedCat.includes(option.value)
                  )}
                  onChange={handleCategoryChange}
                  error={errors.updatedCat}
                />
              </div>
            </div>

            <div
              className="venue-info__form-group"
              style={{ alignItems: "flex-start" }}
            >
              <div className="venue-info__form-group-field">
                <label style={{ padding: "0.5vw 16px" }}>
                  Address
                  <span className="error-message">{errors.updatedAddress}</span>
                </label>
                <GooglePlacesAutocomplete
                  apiKey="AIzaSyCBRxL02Z3nOrMDr9MtsDr6qcYOnmvUquU "
                  autocompletionRequest={{
                    types: googlePlacesTypesToFetch,
                  }}
                  selectProps={{
                    onChange: handleSelect,
                    // inputValue: updatedAddress,
                    placeholder: updatedAddress,
                    styles: {
                      control: (provided) => ({
                        ...provided,
                        background: "rgba(255, 255, 255, 0.1)",
                        border: "none",
                        padding: "4px 16px",
                      }),
                      input: (provided) => ({
                        ...provided,
                        color: "#fff",
                      }),
                      menuList: (provided) => ({
                        ...provided,
                        color: "black",
                      }),
                      singleValue: (provided) => ({
                        ...provided,
                        color: "#fff",
                      }),
                      valueContainer: (provided) => ({
                        ...provided,
                        padding: "0",
                      }),
                    },
                    onInputChange: (val) => setAddressInputValue(val),
                    inputValue: addressInputValue
                  }}
                />
              </div>

              <div
                className="venue-info__form-group-field"
                style={{ flexDirection: "column" }}
              >
                <label style={{ padding: "0.5vw 16px" }}>
                  Hours of operation
                </label>

                <div className="add-venue__hours-of-operation">
                  <div className="add-venue__hours-of-operation__first-hour">
                    <HoursOfOperation
                      excludeDays={omitDays}
                      defaultValues={defaultHourValue}
                      onChangeDay={({ value }) => {
                        setUpdatedHour((oldState) => {
                          if (!oldState?.hours) {
                            return { day: value };
                          }

                          return { ...oldState, day: value };
                        });
                      }}
                      onChangeTime={({ value, type }) => {
                        setUpdatedHour((oldState) => {
                          if (!oldState?.day) {
                            return { hours: { [type]: value } };
                          }

                          const { day, hours } = oldState;

                          return {
                            day,
                            hours: { ...hours, [type]: value },
                          };
                        });
                      }}
                    />

                    {additionalHours.length < 6 && (
                      <button type="button" onClick={handleAddHours}>
                        +
                      </button>
                    )}
                  </div>

                  {additionalHours.map((item, index) => {
                    const defaultValues = item
                      ? {
                        day: item?.day,
                        hours: {
                          from: parseMinutesToHourString(item.hours.from),
                          to: parseMinutesToHourString(item.hours.to),
                        },
                      }
                      : {};

                    return (
                      <div className="add-venue__hours-of-operation__additional-hours">
                        <HoursOfOperation
                          key={index}
                          excludeDays={omitDays}
                          onChangeDay={(event) =>
                            handleOnDayChange(index, event)
                          }
                          onChangeTime={(event) =>
                            handleOnTimeChange(index, event)
                          }
                          defaultValues={defaultValues}
                        />

                        <button
                          type="button"
                          onClick={() => handleRemoveHours(index)}
                        >
                          -
                        </button>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>

            <div className="venue-info__form-group">
              <div className="venue-info__form-group-field">
                <InputForm
                  label="Phone"
                  type="text"
                  id="venuePhone"
                  name="phone"
                  value={updatedPhone}
                  onChange={(event) => setUpdatedPhone(event.target.value)}
                  placeholder={"Enter phone"}
                  error={errors.updatedPhone}
                />
              </div>

              <div className="venue-info__form-group-field">
                <InputForm
                  label="Website"
                  type="text"
                  id="venueSite"
                  name="url"
                  value={updatedUrl}
                  onChange={(event) => setUpdatedUrl(event.target.value)}
                  placeholder={"Enter website"}
                  error={errors.updatedUrl}
                />
              </div>
            </div>

            <div className="venue-info__form-group">
              <div className="venue-info__form-group-field">
                <InputForm
                  label="E-mail"
                  type="email"
                  id="venueEmail"
                  name="email"
                  value={updatedEmail}
                  onChange={(event) => setUpdatedEmail(event.target.value)}
                  placeholder={"Enter e-mail"}
                  error={errors.updatedEmail}
                />
              </div>
              
              <div className="venue-info__form-group-field">
                <SelectForm
                  error={errors.updatedMusic}
                  label="Location"
                  options={locationsOptions}
                  value={locationsOptions.find(opt => opt.value === updatedLocation)}
                  onChange={option => {
                    if (option) setUpdatedLocation(option.value);
                  }}
                />
              </div>
            </div>

            <div className="venue-info__form-group">
              <div className="venue-info__form-group-field">
                <SelectForm
                  label="Dress-code"
                  name="dress"
                  options={dressCodeOptions}
                  className="venue-info__form-group-field-select"
                  value={dressCodeOptions.filter((option) =>
                    updatedDress.includes(option.value)
                  )}
                  onChange={handleDressChange}
                  getOptionValue={(option) => option.value}
                  error={errors.updatedDress}
                />
              </div>

              <div className="venue-info__form-group-field">
                <SelectForm
                  label="Music"
                  isMulti
                  options={musicOptions}
                  className="venue-info__form-group-field-select"
                  value={musicOptions.filter((option) =>
                    updatedMusic.includes(option.value)
                  )}
                  onChange={handleMusicChange}
                  error={errors.updatedMusic}
                />
              </div>
            </div>

            <div className="add-venue__form-block" style={{ marginBottom: 20 }}>
              {images.map((img) => {
                return (
                  <Image
                    key={img.url}
                    onAdd={handleImageAdd}
                    onRemove={() => handleImageRemove(img.id)}
                    defaultValue={img.url}
                  />
                );
              })}
            </div>

            <div className="venue-info__form-group-about">
              <label
                htmlFor="venueDescription"
                className="venue-info__form-group-field-label"
              >
                About
                <span className="error-message">{errors.updatedAbout}</span>
              </label>
              <textarea
                rows={4}
                id="venueDescription"
                name="description"
                className="venue-info__form-group-about-input"
                value={updatedAbout}
                onChange={(event) => setUpdatedAbout(event.target.value)}
              // placeholder={specVenue.about || "Enter description"}
              />
            </div>

            <div className="add-venue__form-block">
              <div
                className="add-venue__form-block-group"
                style={{ width: "100%" }}
              >
                <label
                  htmlFor="venueAbout"
                  className="venue-info__form-group-field-label"
                >
                  More Information (Specials)
                  <span className="error-message">{errors.updateSpecials}</span>
                </label>
                <textarea
                  placeholder="Description of the venue"
                  rows={4}
                  id="venueAbout"
                  name="specials"
                  className="venue-info__form-group-about-input"
                  value={updateSpecials}
                  onChange={(event) => setUpdateSpecials(event.target.value)}
                // placeholder={specVenue.about || "Enter description"}
                />
              </div>
            </div>


            {user?.admin && (
              <div className="container-funds">
                <h1>Venue Funds</h1>

                <InputForm
                  label="Add Funds"
                  id="venueEmail"
                  name="addFunds"
                  value={funds}
                  onChange={(event) => setFunds(event.target.value)}
                  placeholder={"Enter amount in numbers"}
                />
              </div>
            )}




            {!view && (
              <button type="submit" className="venue-info__form-submit">
                <p className="venue-info__form-submit-text">
                  Update information
                </p>

                <img src={checkmark} alt="check mark"></img>
              </button>
            )}
          </div>
        </form>
      )}
    </article>
  );
};

export default VenueInformation;
